<template>
    <div class="question">
        <div class="Qtitle">Compréhension Orale</div>
        <hr>
        <div class="Qlibelle"><li>{{data.main.LibelleQAudio}} :</li></div>
        <div class="Qmain">
            
            <div class="Response">
                
                <div class="medias" style="text-align: center;width:40%;">
                    <img src="@/assets/audio.jpg" style="width:auto" height="200" alt="">
                    <video :src="'https://api.victory-partners.ma/victory%20api/upload/' + data.main.CheminFichierAudio" controls style="width:98%;border: 1px solid black;border-radius: 25px;">
                    </video>
                </div>
                <div class="Rcnt">
                    <div v-for="q in data.sousQues" :key="q.idSousQAudio">
                        <div class="ResponseBox">
                            <div class="Qennonce">{{q.EnonceQestion}}</div>
                            <div v-if="q.Choix1"><input :checked="response.audio.find(x=> x.idAudio == q.idSousQAudio)&&response.audio.find(x=> x.idAudio == q.idSousQAudio).result==q.Choix1?true:false" type="radio" v-on:click="reponse()" :name="q.idSousQAudio" :value="q.Choix1"> {{q.Choix1}}</div>
                            <div v-if="q.Choix2"><input :checked="response.audio.find(x=> x.idAudio == q.idSousQAudio)&&response.audio.find(x=> x.idAudio == q.idSousQAudio).result==q.Choix2?true:false" type="radio" v-on:click="reponse()" :name="q.idSousQAudio" :value="q.Choix2"> {{q.Choix2}}</div>
                            <div v-if="q.Choix3"><input :checked="response.audio.find(x=> x.idAudio == q.idSousQAudio)&&response.audio.find(x=> x.idAudio == q.idSousQAudio).result==q.Choix3?true:false" type="radio" v-on:click="reponse()" :name="q.idSousQAudio" :value="q.Choix3"> {{q.Choix3}}</div>
                            <div v-if="q.Choix4"><input :checked="response.audio.find(x=> x.idAudio == q.idSousQAudio)&&response.audio.find(x=> x.idAudio == q.idSousQAudio).result==q.Choix4?true:false" type="radio" v-on:click="reponse()" :name="q.idSousQAudio" :value="q.Choix4"> {{q.Choix4}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <div class="toolbox" >
            <button v-on:click="next()">Question suivant</button>
            <button v-on:click="terminer">Terminer le test</button>
        </div>
    </div>
</template>

<script>

export default {
    name:'Question',
    props: ['data','i','response'],
    created:function(){
        console.log(this.data)
    },
    methods:{
        next:function(){
            this.$parent.next('a');
        },
        previous:function(){
            this.$parent.previous('a');
        },
        reponse:function(){
            for(const r of this.data.sousQues){
                const aa = document.getElementsByName(r.idSousQAudio)
                for(const a of aa)
                    if(a.checked)
                        this.$parent.audioReponse(r.idSousQAudio,a.value);
            }
        },
        terminer:function(){
            this.$parent.terminer();
        }
    }
}
</script>


<style>
 @media screen and (max-width: 800px) {
     .Response{
        display:block !important;
    }
    .TextBox{
        width: 100% !important;
        border: none !important;
        border-top: 2px dashed blue !important;
        padding: 20px 0 !important;
        text-align: center;
    }
    .Rcnt{
        width: 100% !important;
    }
    .toolbox button:nth-child(2){
        position: initial !important;
    }
    .medias{
        width: 100% !important;
    }
    .medias img{
        width: 100% !important;
        height: auto !important;
        padding: 0 !important;
    }
    .medias audio{
        width: 90% !important;
    }
  }
  @media screen and (max-width: 400px) {
     .Rcnt{
        width: 100%;
        display: grid;
        grid-template-columns: 100% !important;
    }
  }
.question{
    width: 100%;
}
.Qtitle{
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}
.Qlibelle{
    font-size: 20px;
    color: red;
    font-weight: bold;
    margin: 30px 0;
    text-align: center;
}
.Qennonce{
    color: blue;
    padding-left: 0  !important;
}
.ResponseBox{
    border: 2px dashed blue;
    width: 99%;
    padding: 0;
    height: 100%;
}
.ResponseBox div{
    margin: 20px 10px;
    padding-left: 30px;
    width: fit-content !important;
}
.toolbox{
    grid-column: 1/-1;
    background-color: #fafafc !important;
    border-top: 1px solid #e9e9eb;
    border-bottom: 1px solid #e9e9eb;
    text-align: center;
    width: 100%;
    padding: 5px 0;
    position: relative;
    margin-top: 5px;
}
.toolbox button{
    width: 100px;
    padding: 7px 0;
    margin: 0 20px;
}
.toolbox button:nth-child(2){
    background-color: red;
    color: white;
    font-weight: bold;
    width: 150px;
    padding: 15px 0;
    position: absolute;
    right: 0;
}
.toolbox button:nth-child(1){
    background-color: rgb(14, 223, 14);
    color: white;
    font-weight: bold;
    width: 150px;
    padding: 15px 0;
}
.TextBox{
    width: 40%;
    border: 2px dashed blue;
    border-right: none;
    padding: 15px;
    overflow-y: auto;
    overflow-wrap: break-word;
    max-width: 1000px;
    max-height: 500px;
}
.Rcnt{
    width: 60%;
    display: grid;
    grid-template-columns: 49% 49%;
}
.Rcnt .div{
    width: 100%;
    height: 99%;
}
    
</style>