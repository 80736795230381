<template>
  <div class="test">
    <div v-if="alert" class="alert">
      <div class="alr">
        <div class="x" v-on:click="hide">X</div>
        <div class="child alrTitle">Voulez-vous vraiment terminer le test?</div>
        <div style="position: absolute; width: 100%; bottom: 10px" class="child">
          <button v-on:click="yes" class="start">Términer</button
          ><button v-on:click="hide" class="no">Annuler</button>
        </div>
      </div>
    </div>
    <div v-if="Calert" class="alert">
      <div class="alr">
        <div class="x" v-on:click="Chide">X</div>
        <div class="child alrTitle">
          Questions terminé! <br />Voulez-vous terminer le test?
        </div>
        <div style="position: absolute; width: 100%; bottom: 10px" class="child">
          <button v-on:click="yes" class="start">Términer</button
          ><button v-on:click="Chide" class="no">Annuler</button>
        </div>
      </div>
    </div>
    <div class="testCnt">
      <div class="sidebar">
        <div class="menu menuTitle">Questions</div>
        <div class="menuScroll">
          <div class="inner">
            <div
              class="menu"
              v-for="(q, i) in questions.comp"
              :id="'C' + i"
              :key="q.idQComp"
              v-on:click="choose(q, 'c', i)"
            >
              <img src="@/assets/icons/comp.png" width="30" alt="" /><span
                style="margin-right: 30px"
                >Compréhension écrite</span
              ><!--<img
                v-if="response.comp[i].result != null"
                style="position: absolute; right: 0"
                src="@/assets/check.png"
                width="30"
                alt=""
              /><img
                v-if="response.comp[i].result == null"
                style="position: absolute; right: 0"
                src="@/assets/x.png"
                width="30"
                alt=""
              />-->
            </div>
            <div
              class="menu"
              v-for="(q, i) in questions.qsm"
              :key="q.i"
              :style="response.qsm[i].result != null ? 'background-color: #42b742' : ''"
              v-on:click="choose(q, 'q', i)"
            >
              <img src="@/assets/icons/qcm.png" width="30" alt="" /><span
                style="margin-right: 30px"
                >QCM {{ i + 1 }}</span
              ><!--<img
                v-if="response.qsm[i].result != null"
                style="position: absolute; right: 0"
                src="@/assets/check.png"
                width="30"
                alt=""
              /><img
                v-if="response.qsm[i].result == null"
                style="position: absolute; right: 0"
                src="@/assets/x.png"
                width="30"
                alt=""
              />-->
            </div>
            <div
              class="menu"
              v-for="(q, i) in questions.audio"
              :id="'A' + i"
              v-on:click="choose(q, 'a', i)"
              :key="q.i"
            >
              <img src="@/assets/icons/audio.png" width="30" alt="" /><span
                style="margin-right: 30px"
                >Compréhension Orale</span
              ><!--<img
                v-if="response.audio[i].result != null"
                style="position: absolute; right: 0"
                src="@/assets/check.png"
                width="30"
                alt=""
              /><img
                v-if="response.audio[i].result == null"
                style="position: absolute; right: 0"
                src="@/assets/x.png"
                width="30"
                alt=""
              />-->
            </div>
          </div>
        </div>
      </div>
      <div class="questionsCnt">
        <div class="qtool">
          <img
            style="margin: auto"
            height="150"
            src="https://api.victory-partners.ma/victory%20api/upload/images/icons/logo.png"
            alt=""
          />
          <div
            id="timer"
            class="timer"
            style="
              background-color: green;
              display: flex;
              align-items: center;
              padding: 5px;
              border-radius: 20px;
              color: white;
              font-weight: bold;
              margin: auto;
            "
          >
            <span style="padding: 5px"> {{ min + ":" + sec }}min</span>
            <img height="40" src="../assets/icons/chrono.png" alt="" />
          </div>
          <img
            v-if="lang != 9 && niveau >= 5 && niveau <= 10"
            style="margin: auto"
            height="150"
            src="@/assets/jun.jpg"
            alt=""
          />
          <img
            v-if="lang != 9 && niveau >= 11 && niveau <= 14"
            style="margin: auto"
            height="150"
            src="@/assets/ado.jpg"
            alt=""
          />
          <img
            v-if="lang != 9 && niveau >= 15 && niveau <= 20"
            style="margin: auto"
            height="150"
            src="@/assets/adu.jpg"
            alt=""
          />
          <img
            v-if="lang == 9 && niveau >= 5 && niveau <= 10"
            style="margin: auto"
            height="150"
            src="@/assets/kid.jpg"
            alt=""
          />
          <img
            v-if="lang == 9 && niveau >= 11 && niveau <= 14"
            style="margin: auto"
            height="150"
            src="@/assets/teen.jpg"
            alt=""
          />
          <img
            v-if="lang == 9 && niveau >= 15 && niveau <= 20"
            style="margin: auto"
            height="150"
            src="@/assets/adus.jpg"
            alt=""
          />
        </div>
        <hr />
        <Question v-if="c" :data="data" :i="i" :response="response" ref="form" />
        <Qsm v-if="q" :data="data" :i="i" :response="response" ref="form" />
        <Audio v-if="a" :data="data" :i="i" :response="response" ref="form" />
      </div>
    </div>
  </div>
</template>

<script>
function confirmExit() {
  console.log("gg");
  const message = "You have attempted to leave this page. Are you sure?";
  return message;
}
window.onbeforeunload = confirmExit;
/*function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}*/

import { Rest } from "../providers/rest";
import Question from "../components/question";
import Qsm from "../components/Qsm";
import Audio from "../components/Audio";

export default {
  components: {
    Question,
    Qsm,
    Audio,
  },
  data() {
    return {
      questions: [],
      alert: false,
      Calert: false,
      min: null,
      sec: null,
      data: null,
      c: null,
      q: null,
      a: null,
      i: 1,
      Ti: 1,
      idTest: this.$route.params.idTest,
      idClient: this.$route.params.idClient,
      niveau: this.$route.params.niveau,
      lang: this.$route.params.lang,
      time: this.$route.params.time,
      qcm: this.$route.params.qcm,
      comp: this.$route.params.comp,
      audio: this.$route.params.audio,
      note: this.$route.params.note,
      response: { comp: [], qsm: [], audio: [] },
      timmer: null,
    };
  },
  methods: {
    Cshow: function () {
      this.Calert = true;
    },
    Chide: function () {
      this.Calert = false;
    },
    choose: function (Q, type, i) {
      if (type == "c") {
        this.c = true;
        this.q = false;
        this.a = false;
      } else if (type == "q") {
        this.c = false;
        this.q = true;
        this.a = false;
      } else if (type == "a") {
        this.c = false;
        this.q = false;
        this.a = true;
      }
      this.data = Q;
      this.i = i + 1;
    },
    next: function (type) {
      if (type == "c") {
        if (this.i < this.questions.comp.length) {
          this.data = this.questions.comp[this.i];
          this.i++;
        } else if (this.questions.qsm.length > 0) {
          this.data = this.questions.qsm[0];
          this.q = true;
          this.c = false;
          this.i = 1;
        } else if (this.questions.audio.length > 0) {
          this.data = this.questions.audio[0];
          this.a = true;
          this.c = false;
          this.i = 1;
        } else if (this.questions.qsm.length == 0 && this.questions.audio.length == 0) {
          this.Cshow();
        }
      } else if (type == "q") {
        if (this.i < this.questions.qsm.length) {
          this.data = this.questions.qsm[this.i];
          this.i++;
        } else if (this.questions.audio.length > 0) {
          this.data = this.questions.audio[0];
          this.a = true;
          this.q = false;
          this.i = 1;
        } else if (this.questions.audio.length == 0) {
          this.Cshow();
        }
      } else if (type == "a") {
        if (this.i < this.questions.audio.length) {
          this.data = this.questions.audio[this.i];
          this.i++;
        } else {
          this.Cshow();
        }
      }
    },
    previous: function (type) {
      if (type == "c") {
        if (this.i > 1) {
          this.i--;
          this.data = this.questions.comp[this.i - 1];
        }
      } else if (type == "q") {
        if (this.i > 1) {
          this.i--;
          this.data = this.questions.qsm[this.i - 1];
        } else if (this.questions.comp.length > 0) {
          this.data = this.questions.comp[this.questions.comp.length - 1];
          this.c = true;
          this.q = false;
          this.i = this.questions.comp.length;
        }
      } else if (type == "a") {
        if (this.i > 1) {
          this.i--;
          this.data = this.questions.audio[this.i - 1];
        } else if (this.questions.qsm.length > 0) {
          this.data = this.questions.qsm[this.questions.qsm.length - 1];
          this.a = false;
          this.q = true;
          this.i = this.questions.qsm.length;
        } else if (this.questions.comp.length > 0) {
          this.data = this.questions.comp[this.questions.comp.length - 1];
          this.c = true;
          this.a = false;
          this.i = this.questions.comp.length;
        }
      }
    },
    qsmReponse: function (idQsm, Result) {
      let breake = true;
      for (let i = 0; i < this.response.qsm.length; i++) {
        if (this.response.qsm[i].idQsm == idQsm) {
          this.response.qsm[i].result = Result;
          breake = false;
        }
      }
      if (breake) this.response.qsm.push({ idQsm: idQsm, result: Result });
    },
    compReponse: function (idComp, Result) {
      let breake = true;
      let j = 0;
      for (let i = 0; i < this.response.comp.length; i++) {
        if (this.response.comp[i].idComp == idComp) {
          this.response.comp[i].result = Result;
          breake = false;
        }
      }
      if (breake) this.response.comp.push({ idComp: idComp, result: Result });
      for (let i = 0; i < this.questions.comp.length; i++) {
        if (
          this.response.comp[j].result != null &&
          this.response.comp[j + 1].result != null &&
          this.response.comp[j + 2].result != null &&
          this.response.comp[j + 3].result != null
        ) {
          document.getElementById("C" + i).style.backgroundColor = "#42b742";
        }
        j += 4;
      }
    },
    audioReponse: function (idAudio, Result) {
      let breake = true;
      let j = 0;
      for (let i = 0; i < this.response.audio.length; i++) {
        if (this.response.audio[i].idAudio == idAudio) {
          this.response.audio[i].result = Result;
          breake = false;
        }
      }
      if (breake) this.response.audio.replace({ idAudio: idAudio, result: Result });
      for (let i = 0; i < this.questions.audio.length; i++) {
        if (
          this.response.audio[j].result != null &&
          this.response.audio[j + 1].result != null &&
          this.response.audio[j + 2].result != null &&
          this.response.audio[j + 3].result != null
        ) {
          document.getElementById("A" + i).style.backgroundColor = "#42b742";
        }
        j += 4;
      }
    },
    terminer: function () {
      this.show();
    },
    show: function () {
      this.alert = true;
    },
    hide: function () {
      this.alert = false;
    },
    yes: function () {
      window.onbeforeunload = null;
      let i = 0;
      let length = 0;
      for (const res of this.response.qsm) {
        length++;
        if (res.result == res.bonne) i++;
      }
      for (const res of this.response.comp) {
        length++;
        if (res.result == res.bonne) i++;
      }
      for (const res of this.response.audio) {
        length++;
        if (res.result == res.bonne) i++;
      }
      let succes = "";
      if (i < this.note) {
        succes = "false";
      } else {
        succes = "true";
      }
      const result = i.toString() + "/" + length.toString();
      const data = {
        id: this.idTest,
        result: result,
        succes: succes,
      };
      new Rest()
        .endTest(data)
        .then(async (r) => {
          if (r.data.success) {
            const gg = async (d) => {
              await new Rest()
                .addAdmin(d)
                .then(async (r) => {
                  console.log(r.data.success);
                  if (r.data.success == false) {
                    //await gg(d);
                    console.log("gg");
                  }
                })
                .catch((e) => console.log(e));
            };
            for (const res of this.response.comp) {
              const d = {
                type: "det",
                idTest: this.idTest,
                idClient: this.idClient,
                ennonce: res.enonce,
                bonne: res.bonne,
                response: res.result ?? "null",
                qtype: "comp",
                mat: this.lang,
                result: res.bonne == res.result ? "true" : "false",
              };
              await gg(d);
            }
            for (const res of this.response.qsm) {
              const d = {
                type: "det",
                idTest: this.idTest,
                idClient: this.idClient,
                ennonce: res.enonce,
                bonne: res.bonne,
                qtype: "qcm",
                mat: this.lang,
                response: res.result ?? "null",
                result: res.bonne == res.result ? "true" : "false",
              };
              //await sleep(500);
              await gg(d);
            }
            for (const res of this.response.audio) {
              const d = {
                type: "det",
                idTest: this.idTest,
                idClient: this.idClient,
                ennonce: res.enonce,
                bonne: res.bonne,
                qtype: "audio",
                mat: this.lang,
                response: res.result ?? "null",
                result: res.bonne == res.result ? "true" : "false",
              };
              await gg(d);
            }
            clearTimeout(this.timmer);
            this.$router.replace({
              name: "Result",
              params: {
                idTest: this.idTest,
                result: result,
                succes: succes,
                response: JSON.stringify(this.response),
              },
            });
          } else {
            alert(r.data.msg);
          }
        })
        .catch((e) => alert(e));
    },
  },
  beforeMount: function () {
    if (
      !this.$route.params.niveau ||
      !this.$route.params.lang ||
      !this.$route.params.time
    ) {
      this.$router.replace("/");
    } else {
      new Rest()
        .getQuestions(this.lang, this.niveau, this.qcm, this.comp, this.audio)
        .then((r) => {
          if (r.data.success) {
            this.questions = r.data;
            if (r.data.comp.length > 0) {
              for (const p of r.data.comp) {
                for (const c of p.sousQues) {
                  let bonne;
                  if (c.BonneReponse == "A") bonne = c.Choix1;
                  else if (c.BonneReponse == "B") bonne = c.Choix2;
                  else if (c.BonneReponse == "C") bonne = c.Choix3;
                  else if (c.BonneReponse == "D") bonne = c.Choix4;
                  else bonne = "null";
                  this.response.comp.push({
                    idComp: c.idSousQComp,
                    result: null,
                    bonne: bonne,
                    enonce: c.EnonceQuestion,
                  });
                }
              }
            }
            if (r.data.qsm.length > 0) {
              for (const c of r.data.qsm) {
                let bonne;
                if (c.BonneReponseQCM == "A") bonne = c.Choix1QCM;
                else if (c.BonneReponseQCM == "B") bonne = c.Choix2QCM;
                else if (c.BonneReponseQCM == "C") bonne = c.Choix3QCM;
                else if (c.BonneReponseQCM == "D") bonne = c.Choix4QCM;
                else bonne = "null";
                this.response.qsm.push({
                  idQsm: c.idQCM,
                  result: null,
                  bonne: bonne,
                  enonce: c.EnonceQCM,
                });
              }
            }
            if (r.data.audio.length > 0) {
              for (const p of r.data.audio) {
                for (const c of p.sousQues) {
                  let bonne;
                  if (c.BonneReponse == "A") bonne = c.Choix1;
                  else if (c.BonneReponse == "B") bonne = c.Choix2;
                  else if (c.BonneReponse == "C") bonne = c.Choix3;
                  else if (c.BonneReponse == "D") bonne = c.Choix4;
                  else bonne = "null";
                  this.response.audio.push({
                    idAudio: c.idSousQAudio,
                    result: null,
                    bonne: bonne,
                    enonce: c.EnonceQestion,
                  });
                }
              }
            }
            if (r.data.comp.length > 0) {
              this.data = r.data.comp[0];
              this.c = true;
            } else if (r.data.qsm.length > 0) {
              this.data = r.data.qsm[0];
              this.q = true;
            } else if (r.data.audio.length > 0) {
              this.data = r.data.audio[0];
              this.a = true;
            }
          }
        });
    }
  },
  created: function () {
    this.min = this.time;
    this.sec = 0;
    setInterval(() => {
      if (this.min >= 0)
        if (this.sec == 0) {
          if (this.min != 0) {
            this.sec = 59;
            this.min--;
          }
          if (this.min == 10) {
            document.getElementById("timer").style.backgroundColor = "#dcdc61";
          }
          if (this.min == 3) {
            document.getElementById("timer").style.backgroundColor = "red";
          }
        } else {
          this.sec--;
        }
    }, 1000);
    const time = this.time * 60 * 1000;
    this.timmer = setTimeout(() => {
      this.yes();
    }, time);
  },
};
</script>

<style>
#nav {
  display: none;
}
.testTitle {
  padding: 20px;
  font-weight: bold;
  width: 50%;
  text-align: center;
}
.qtool {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1200px) {
  .qtool img {
    width: 30% !important;
    height: auto !important;
  }
  .qtool .timer img {
    width: auto !important;
    height: 40px !important;
  }
}

@media screen and (max-width: 1000px) {
  .testCnt {
    display: block !important;
  }
  .sidebar {
    width: 100% !important;
    height: fit-content !important;
  }
  .menuScroll {
    width: 100% !important;
    height: fit-content !important;
    overflow-x: auto;
    overflow-y: hidden !important;
    -webkit-overflow-scrolling: touch !important;
  }
  .inner {
    height: 100%;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch !important;
  }
  .menu {
    display: inline-flex !important;
    margin: 5px;
    border-bottom: none !important;
    border-left: 1px solid black;
    -webkit-overflow-scrolling: touch !important;
    padding: 15px 10px !important;
  }
  .menuTitle {
    width: 100%;
    border-bottom: 1px solid !important;
    border-left: none !important;
    padding: 10px 0 !important;
    margin: 0 !important;
    text-align: center;
    display: block !important;
  }
  .questionsCnt {
    width: 100% !important;
  }
}

.count {
  width: 50%;
  display: flex;
  align-items: center;
  padding-right: 50px;
}
.count span {
  float: right;
}
.testCnt {
  width: 100%;
  display: flex;
}
.sidebar {
  width: 20%;
  height: 100%;
  height: 100vh;
  background-color: #fafafc;
  border-right: 1px solid #e9e9eb;
}
.menu {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  position: relative;
}
.menu img {
  margin-right: 5px;
}
.menu span:nth-child(1) {
  text-align: end;
}
.menuTitle {
  font-weight: bold;
  color: blue;
  font-size: 20px;
  cursor: auto;
  border-color: blue;
  text-align: center;
  display: block;
}
.menuScroll {
  overflow-y: auto;
  height: calc(100% - 44px);
}
.menuScroll .menu:hover {
  font-weight: bold;
  border-width: 3px;
}
.questionsCnt {
  width: 80%;
}
.test .alert {
  background-color: #ffffff9c !important;
  width: 100% !important;
  height: 100vh !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 2 !important;
  display: flex !important;
  align-items: center !important;
}
.test .alert .alr {
  width: 600px !important;
  height: 420px !important;
  background-color: #0e95d3 !important;
  background-image: url("../assets/popup.png") !important;
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin: auto !important;
  border-radius: 20px !important;
  position: relative !important;
  padding: 25px 0 !important;
}
.test .alert .alr .x {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 20px !important;
  padding: 5px 10px !important;
  text-align: center !important;
  border-top-right-radius: 20px !important;
  cursor: pointer !important;
}
.test .alert .alr .x:hover {
  background-color: #4edc54 !important;
}
.test .child {
  text-align: center !important;
  color: white !important;
  margin: 10px 0 20px 0 !important;
}
.test .alrTitle {
  font-weight: bold !important;
  font-size: 20px !important;
  width: 50% !important;
  margin-left: auto !important;
  margin-right: 30px !important;
  margin-top: 50px !important;
}
.test .child input {
  width: 70% !important;
  padding: 5px !important;
}
.test .child input::placeholder {
  text-align: center !important;
  font-size: 15px !important;
}
.test .start {
  width: 30% !important;
  padding: 7px !important;
  background-color: red !important;
  cursor: pointer !important;
  font-weight: bold !important;
  font-size: 20px !important;
  color: white !important;
  margin: 0 5px !important;
}
.test .no {
  width: 30% !important;
  padding: 7px !important;
  cursor: pointer !important;
  font-weight: bold !important;
  font-size: 20px !important;
  background-color: #4edc54 !important;
  color: white !important;
  margin: 0 5px !important;
}
</style>
