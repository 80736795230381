<template>
  <div class="question">
    <div class="Qtitle" style="padding: 15px; color: blue">
      Questuions Compréhension écrite
    </div>
    <hr />
    <div class="Qlibelle">
      <li>{{ data.main.LibelleQComp }} :</li>
      <div style="margin: 5px">
        <audio
          v-if="data.main.audio"
          controls
          style="border: 1px solid black; border-radius: 25px"
        >
          <source
            :src="
              'https://api.victory-partners.ma/victory%20api/upload/' + data.main.audio
            "
          />
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      </div>
    </div>
    <div class="Qmain">
      <div class="Response">
        <div v-if="data.main.CheminImage" class="TextBox" style="text-align: center">
          <img
            style="padding: 0 !important; width: 70%"
            :src="'https://api.victory-partners.ma/victory%20api/upload/images/logo.png'"
            alt=""
          />
        </div>
        <div v-if="!data.main.CheminImage" class="TextBox">
          <div style="text-align: center; font-weight: bold">
            Text : <span style="color: red">{{ data.main.TitreTexteQComp }}</span>
          </div>
          <br />
          {{ data.main.TextQComp }}
        </div>
        <div class="Rcnt">
          <div class="div" v-for="q in data.sousQues" :key="q.idSousQComp">
            <div class="ResponseBox">
              <div class="Qennonce">{{ q.EnonceQuestion }}</div>
              <div v-if="q.Choix1">
                <input
                  :checked="
                    response.comp.find((x) => x.idComp == q.idSousQComp) &&
                    response.comp.find((x) => x.idComp == q.idSousQComp).result ==
                      q.Choix1
                      ? true
                      : false
                  "
                  type="radio"
                  v-on:click="reponse()"
                  :name="q.idSousQComp"
                  :value="q.Choix1"
                />
                {{ q.Choix1 }}
              </div>
              <div v-if="q.Choix2">
                <input
                  :checked="
                    response.comp.find((x) => x.idComp == q.idSousQComp) &&
                    response.comp.find((x) => x.idComp == q.idSousQComp).result ==
                      q.Choix2
                      ? true
                      : false
                  "
                  type="radio"
                  v-on:click="reponse()"
                  :name="q.idSousQComp"
                  :value="q.Choix2"
                />
                {{ q.Choix2 }}
              </div>
              <div v-if="q.Choix3">
                <input
                  :checked="
                    response.comp.find((x) => x.idComp == q.idSousQComp) &&
                    response.comp.find((x) => x.idComp == q.idSousQComp).result ==
                      q.Choix3
                      ? true
                      : false
                  "
                  type="radio"
                  v-on:click="reponse()"
                  :name="q.idSousQComp"
                  :value="q.Choix3"
                />
                {{ q.Choix3 }}
              </div>
              <div v-if="q.Choix4">
                <input
                  :checked="
                    response.comp.find((x) => x.idComp == q.idSousQComp) &&
                    response.comp.find((x) => x.idComp == q.idSousQComp).result ==
                      q.Choix4
                      ? true
                      : false
                  "
                  type="radio"
                  v-on:click="reponse()"
                  :name="q.idSousQComp"
                  :value="q.Choix4"
                />
                {{ q.Choix4 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toolbox">
      <button v-on:click="next()">Question suivant</button
      ><button v-on:click="terminer">Terminer le test</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Question",
  props: ["data", "i", "response"],
  created: function () {
    console.log(this.data);
  },
  methods: {
    next: function () {
      this.$parent.next("c");
    },
    previous: function () {
      this.$parent.previous("c");
    },
    reponse: function () {
      for (const r of this.data.sousQues) {
        const aa = document.getElementsByName(r.idSousQComp);
        for (const a of aa)
          if (a.checked) this.$parent.compReponse(r.idSousQComp, a.value);
      }
    },
    terminer: function () {
      this.$parent.terminer();
    },
  },
};
</script>

<style>
@media screen and (max-width: 800px) {
  .Response {
    display: block !important;
  }
  .TextBox {
    width: 100% !important;
    border: none !important;
    border-top: 2px dashed blue !important;
    padding: 20px 0 !important;
    text-align: center;
  }
  .Rcnt {
    width: 100% !important;
  }
  .toolbox button:nth-child(2) {
    position: initial !important;
  }
}
@media screen and (max-width: 400px) {
  .Rcnt {
    width: 100%;
    display: grid;
    grid-template-columns: 100% !important;
  }
}
.question {
  width: 100%;
}
.Qtitle {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.Qmain {
  display: block;
  width: fit-content;
  margin: auto;
  height: 60%;
}
.Qmain img {
  width: 90%;
  padding: 10px;
}
.Response {
  display: flex;
}
.Qlibelle {
  font-size: 20px;
  color: red;
  font-weight: bold;
  margin: 30px 0;
  text-align: center;
}
.Qennonce {
  color: blue;
  padding-left: 0 !important;
}
.ResponseBox {
  border: 2px dashed blue;
  width: 99%;
  padding: 0;
  height: 100%;
}
.ResponseBox div {
  margin: 20px 10px;
  padding-left: 30px;
  width: fit-content !important;
}
.toolbox {
  grid-column: 1/-1;
  background-color: #fafafc !important;
  border-top: 1px solid #e9e9eb;
  border-bottom: 1px solid #e9e9eb;
  text-align: center;
  width: 100%;
  padding: 5px 0;
  position: relative;
  margin-top: 5px;
}
.toolbox button {
  width: 100px;
  padding: 7px 0;
  margin: 0 20px;
}
.toolbox button:nth-child(2) {
  background-color: red;
  color: white;
  font-weight: bold;
  width: 150px;
  padding: 15px 0;
  position: absolute;
  right: 0;
}
.toolbox button:nth-child(1) {
  background-color: rgb(14, 223, 14);
  color: white;
  font-weight: bold;
  width: 150px;
  padding: 15px 0;
}
.TextBox {
  width: 40%;
  border: 2px dashed blue;
  border-right: none;
  padding: 15px;
  overflow-y: auto;
  overflow-wrap: break-word;
  max-width: 1000px;
  max-height: 500px;
  font-size: 16px;
  letter-spacing: 0.5px;
}
.Rcnt {
  width: 60%;
  display: grid;
  grid-template-columns: 49% 49%;
}
.Rcnt .div {
  width: 100%;
  height: 99%;
}
</style>
