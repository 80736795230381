<template>
    <div class="question">
        <div class="Qtitle" style="padding: 15px;color:blue">QSM {{i}}</div>
        <hr>
        <div class="Qlibelle"><li>{{data.LibelleQCM}} :</li></div>
        <div class="Qmain">
            <div >
                <img height="400" style="padding:0 !important;width:auto" v-if="!data.CheminImage" src="../assets/Q.jpg" alt="">
                <img height="400" style="padding:0 !important;width:auto" v-if="data.CheminImage" :src="'https://api.victory-partners.ma/victory%20api/upload/' + data.CheminImage" alt="">
            </div>
            <div class="Response">
                <div class="ResponseBox">
                    <div class="Qennonce">{{data.EnonceQCM}}</div>
                    <div v-if="data.Choix1QCM"><input :checked="response.qsm.find(x=> x.idQsm == data.idQCM)&&response.qsm.find(x=> x.idQsm == data.idQCM).result==data.Choix1QCM?true:false" v-on:click="reponse()" type="radio" :name="data.idQCM" :value="data.Choix1QCM"> {{data.Choix1QCM}}</div>
                    <div v-if="data.Choix2QCM"><input :checked="response.qsm.find(x=> x.idQsm == data.idQCM)&&response.qsm.find(x=> x.idQsm == data.idQCM).result==data.Choix2QCM?true:false" v-on:click="reponse()" type="radio" :name="data.idQCM" :value="data.Choix2QCM"> {{data.Choix2QCM}}</div>
                    <div v-if="data.Choix3QCM"><input :checked="response.qsm.find(x=> x.idQsm == data.idQCM)&&response.qsm.find(x=> x.idQsm == data.idQCM).result==data.Choix3QCM?true:false" v-on:click="reponse()" type="radio" :name="data.idQCM" :value="data.Choix3QCM"> {{data.Choix3QCM}}</div>
                    <div v-if="data.Choix4QCM"><input :checked="response.qsm.find(x=> x.idQsm == data.idQCM)&&response.qsm.find(x=> x.idQsm == data.idQCM).result==data.Choix4QCM?true:false" v-on:click="reponse()" type="radio" :name="data.idQCM" :value="data.Choix4QCM"> {{data.Choix4QCM}}</div>
                </div>
            </div>
        </div>
        <div class="toolbox" >
            <button v-on:click="next()">Question suivant</button>
            <button v-on:click="terminer">Terminer le test</button>
        </div>
    </div>
</template>

<script>

export default {
    name:'Question',
    props: ['data','i','response'],
    data() {
        return {
            in: 0
        }
    },
    created:function(){
        console.log(this.data);
            
    },
    methods:{
        next:function(){
            this.$parent.next('q');
        },
        previous:function(){
            this.$parent.previous('q');
        },
        reponse:function(){
            const aa = document.getElementsByName(this.data.idQCM)
            for(const a of aa)
                if(a.checked)
                    this.$parent.qsmReponse(this.data.idQCM,a.value);
            
        },
        terminer:function(){
            this.$parent.terminer();
        }
    }
}
</script>


<style>
.Qmain{
    display: flex;
    width: 100%;
}
.Response{
    width: 100%;
}
 @media screen and (max-width: 800px) {
     .Response{
        display:block !important;
    }
    .Qmain{
        display: block !important;
    }
    .Qmain div{
        width: 100% !important;
    }
    .Qmain img{
        width: 100% !important;
        height: auto !important;
    }
    .TextBox{
        width: 100% !important;
        border: none !important;
        border-top: 2px dashed blue !important;
        padding: 20px 0 !important;
        text-align: center;
    }
    .Rcnt{
        width: 100% !important;
    }
    .toolbox button:nth-child(2){
        position: initial !important;
    }
  }
  @media screen and (max-width: 400px) {
     .Rcnt{
        width: 100%;
        display: grid;
        grid-template-columns: 100% !important;
    }
  }
.question{
    width: 100%;
}
.Qtitle{
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}


.Qlibelle{
    font-size: 20px;
    color: red;
    font-weight: bold;
    margin: 30px 0;
    text-align: center;
}
.Qennonce{
    color: blue;
    padding-left: 0  !important;
}
.ResponseBox{
    border: 2px dashed blue;
    width: 99%;
    padding: 0;
    height: 100%;
}
.ResponseBox div{
    margin: 20px 10px;
    padding-left: 30px;
    width: fit-content !important;
}
.toolbox{
    grid-column: 1/-1;
    background-color: #fafafc !important;
    border-top: 1px solid #e9e9eb;
    border-bottom: 1px solid #e9e9eb;
    text-align: center;
    width: 100%;
    padding: 5px 0;
    position: relative;
    margin-top: 5px;
}
.toolbox button{
    width: 100px;
    padding: 7px 0;
    margin: 0 20px;
}
.toolbox button:nth-child(2){
    background-color: red;
    color: white;
    font-weight: bold;
    width: 150px;
    padding: 15px 0;
    position: absolute;
    right: 0;
}
.toolbox button:nth-child(1){
    background-color: rgb(14, 223, 14);
    color: white;
    font-weight: bold;
    width: 150px;
    padding: 15px 0;
}
.TextBox{
    width: 40%;
    border: 2px dashed blue;
    border-right: none;
    padding: 15px;
    overflow-y: auto;
    overflow-wrap: break-word;
    max-width: 1000px;
    max-height: 500px;
}
.Rcnt{
    width: 60%;
    display: grid;
    grid-template-columns: 49% 49%;
}
.Rcnt .div{
    width: 100%;
    height: 99%;
}
    
</style>